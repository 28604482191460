// @flow

import * as React from 'react';
import { IntlProvider } from 'react-intl';
// import moment from 'moment';

import '../../../../config/i18n';
import '../../../../config/i18n/localeData';
import * as messages from '../../../../config/i18n/messages';
import i18n from '../i18n';
import type { GatsbyNode$LocalizedPage } from '../types';

export type Props = {
  pageContext: $ElementType<GatsbyNode$LocalizedPage<>, 'context'>,
  children: React.Node,
};

const AppIntlProvider = ({
  children,
  pageContext: { locale },
}: Props): React.Node => {
  const defaultLocale = i18n.getDefaultLocale();

  React.useEffect(() => {
    // moment.locale(locale.id);
  }, [locale]);

  return (
    <IntlProvider
      locale={locale.id}
      defaultLocale={defaultLocale && defaultLocale.id}
      messages={messages[locale.id]}
    >
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
