// @flow
import type { State, Action } from '../types';

import * as actions from './actions';

export const initialState: State = {
  open: false,
  messages: [],
};

export default function snackbarReducer(
  state: State = initialState,
  action: Action,
) {
  switch (action.type) {
    case actions.SHOW_MESSAGE:
      return {
        ...state,
        open: state.messages.length === 0,
        messages: [...state.messages, action.payload],
      };
    case actions.SHOW_NEXT_MESSAGE: {
      const [unusedFirstMessage, ...messages] = state.messages;
      return {
        ...state,
        open: messages.length > 0,
        messages,
      };
    }
    case actions.OPEN:
      return { ...state, open: true };
    case actions.CLOSE:
      return { ...state, open: false };
    default:
      return state;
  }
}
